import request from '@/utils/request'

/**
 *
 * @param {*} data
 * @returns 留言提交(发送邮箱)
 */
export function submitInfo(data) {
  return request({
    url: '/front/lgmsg/add',
    method: 'post',
    data
  })
}
