<template>
  <div>
    <div class="banner"></div>
    <div class="companyProfile">
      <div class="title">{{ $t('公司简介') }}</div>
      <div class="info">
        <div>
          <div :class="$i18n.locale == 'en' ? 'en_item' : 'zh_item'">
            <div class="i"></div>
            <div class="text">
              {{
                $t(
                  '珠海傲视创新科技有限公司于2021年3月在高新区注册成立，是一家专注于研发AI视觉识别算法、行业应用及解决方案的人工智能科技公司。'
                )
              }}
            </div>
          </div>
          <div :class="$i18n.locale == 'en' ? 'en_item' : 'zh_item'">
            <div class="i"></div>
            <div class="text">
              {{
                $t(
                  '公司拥有多名资深的神经网络、机器学习和机器视觉专家，毕业于清华大学、北京大学和上海交大计算机系，是公司的核心竞争优势。'
                )
              }}
            </div>
          </div>
          <div :class="$i18n.locale == 'en' ? 'en_item' : 'zh_item'">
            <div class="i"></div>
            <div class="text">
              {{
                $t(
                  '公司致力于打造零编程、自动化、高精度、通用的AI视觉识别算法模型。'
                )
              }}
            </div>
          </div>
          <div :class="$i18n.locale == 'en' ? 'en_item' : 'zh_item'">
            <div class="i"></div>
            <div class="text">
              {{
                $t(
                  '目前公司专注于新零售、新能源领域的AI人工智能技术的研发和实际应用，帮助客户极大地提升了工作效率。'
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="coreTeamContainer">
      <div
        class="w"
        :class="$i18n.locale == 'en' ? 'en_coreTeam' : 'zh_coreTeam'"
      >
        <div class="title">{{ $t('核心团队') }}</div>
        <div class="img_box">
          <div class="left">
            <div class="photo">
              <img src="../../../assets/关于我们/雷明.png" alt="" />
            </div>
            <div class="name">{{ $t('雷明') }}</div>
            <div class="left_title" v-show="$i18n.locale == 'zh'">
              首席科学家 机器学习和视觉权威专家
            </div>
            <div class="left_title" v-show="$i18n.locale == 'en'">
              <div>Chief Scientist</div>
              <div>Senior machine learning, machine vision expert</div>
            </div>
            <div class="info" v-show="$i18n.locale == 'zh'">
              2009年毕业于清华大学计算机系，获硕士学位，研究方向为机器视觉与机器学习。
              2006-2009年期间独立在高影响因子国际期刊、会议上发表论文数篇，拥有10篇机器视觉/机器学习方向的发明专利。
              曾就职于百度，任高级软件工程师（T5）、项目经理，负责百度PC版浏览器研发，带领20+人团队。
              2012-2018年，任全球化智能家居公司zmodo +
              meshare的CTO&技术合伙人，面向北美地区等海外市场，估值曾超过100亿人民币，从零建立近200人的软硬件研发团队，参与3轮融资。
              《机器学习的数学》（人民邮电出版社，2021.01），《机器学习-原理-算法与应用》（清华大学出版社，2019.09）唯一作者，销量超过5万册，这两本书目前已经被国内多所高校选做教材。
            </div>
            <div class="info" v-show="$i18n.locale == 'en'">
              <div class="info_p">
                In 2009, he graduated from the Department of Computer Science,
                Tsinghua University with a master's degree. His research
                direction is machine vision and machine learning.
              </div>
              <div class="info_p">
                From 2006 to 2009, he independently published several papers in
                international journals and conferences with high impact factors,
                and held 10 invention patents in the direction of machine
                vision/machine learning.
              </div>
              <div class="info_p">
                Once worked in Baidu as senior software engineer (T5) and
                project manager, responsible for the research and development of
                Baidu PC browser, leading a team of more than 20 people.
              </div>
              <div class="info_p">
                From 2012 to 2018, he worked as the CTO & technology partner in
                Zmodo + Meshare, a global smart home company, targeting overseas
                markets such as North America, with an estimated value of more
                than 10 billion yuan. He built a software and hardware R&D team
                of nearly 200 people from zero and participated in 3 rounds of
                financing investment.
              </div>
              <div class="info_p">
                The only author of "The Mathematics of Machine Learning" (Posts
                and Telecommunications Press, 2021.01) and "Machine Learning -
                Principles - Algorithms and Applications" (Tsinghua University
                Press, 2019.09), with sales of more than 50,000 copies. These
                two books have been selected as teaching material by many
                universities in China.
              </div>
            </div>
          </div>
          <div class="right">
            <div class="photo">
              <img src="../../../assets/关于我们/刘彬.png" alt="" />
            </div>
            <div class="name">{{ $t('刘彬') }}</div>
            <div class="right_title" v-show="$i18n.locale == 'zh'">
              资深顾问 机器学习和视觉资深专家
            </div>
            <div class="right_title" v-show="$i18n.locale == 'en'">
              <div>Senior consultant</div>
              <div>Senior machine vision, machine learning expert</div>
            </div>
            <div class="info" v-show="$i18n.locale == 'zh'">
              2011年毕业于上海交通大学计算系，获博士学位，研究方向为机器视觉与机器学习。
              毕业后曾就职于英特尔中国研发中心，任算法工程师；百度IDL，任高级算法工程师；阿里达摩院，任深度学习算法专家。
              YI+AI
              联合创始人&CTO，负责AI业务线的技术产品研发，参与公司的多轮融资。2019年1月被国际知名消费硬件公司收购之后任该公司北京研发中心计算机视觉/机器学习负责人，期间主导核心3D感知建模产品从0到1的研发以及AI相机等产品的优化提升。
              发表高水平国际会议论文数篇，Google
              scholar累计引用次数超1200次，单篇引用超800次，拥有中美人工智能相关专利数十个。
            </div>
            <div class="info" v-show="$i18n.locale == 'en'">
              <div class="info_p">
                In 2011, he graduated from the Department of Computer Science,
                Shanghai Jiao Tong University with a PhD in machine vision and
                machine learning.
              </div>
              <div class="info_p">
                After graduation, he worked as an algorithm engineer in Intel
                China R&D Center; senior algorithm engineer in Baidu IDL; deep
                learning algorithm expert in Alibaba Dharma Institute.
              </div>
              <div class="info_p">
                Co-founder & CTO of YI+AI, responsible for technology research
                and development of AI business line, and participated in
                multiple rounds of financing of the company. After being
                acquired by an internationally renowned consumer hardware
                company in January 2019, he was the head of computer
                vision/machine learning at the company's R&D center in Beijing.
                During this period, he led the R&D of core 3D perception
                modeling products from 0 to 1 and the optimization and
                improvement of AI cameras and other products.
              </div>
              <div class="info_p">
                He has published several high-level international conference
                papers, and has been cited more than 1200 times by Google
                scholar and more than 800 times by a single paper. He has dozens
                of AI related patents in China and USA.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="companyPatent">
      <div class="title">{{ $t('公司专利') }}</div>
      <div class="img">
        <img src="../../../assets/关于我们/公司专利.png" alt="" />
      </div>
    </div>
    <div class="contactUsContainer">
      <div class="contactUs w">
        <div class="title">{{ $t('联系我们') }}</div>
        <div class="centent">
          <div class="left">
            <div class="logo">
              <img src="../../../assets/img/logo.png" alt="" />
            </div>
            <div class="content">
              <div class="content_item">
                <div class="content_item_title">{{ $t('地址：') }}</div>
                <div class="info">
                  {{
                    $t(
                      '珠海市高新区唐家湾镇湾创路88号706室（港湾1号·创意云端A栋）'
                    )
                  }}
                </div>
              </div>
              <div class="content_item">
                <div>{{ $t('电话：') }}</div>
                <div>{{ $t('13346402280') }}</div>
              </div>
              <!-- <div>邮箱： info@osai.cc</div> -->
              <div class="content_item">
                <div>{{ $t('邮箱：') }}</div>
                <div>{{ $t('info@osai.cc') }}</div>
              </div>
            </div>
            <div class="qrcode_box" v-show="$i18n.locale == 'zh'">
              <div class="item">
                <img src="../../../assets/二维码图标/公众号.png" alt="" />
                <div>微信公众号</div>
              </div>
              <div class="item">
                <img src="../../../assets/二维码图标/视频号.png" alt="" />
                <div>企业视频号</div>
              </div>
            </div>
          </div>
          <div class="right">
            <el-form ref="form" :model="form" :rules="rules">
              <!-- <el-form-item>
              <el-input placeholder="留言标题" v-model="form.name"></el-input>
            </el-form-item> -->
              <el-form-item prop="ct">
                <el-input
                  :placeholder="$t('留言内容（必填）')"
                  resize="none"
                  type="textarea"
                  v-model="form.ct"
                ></el-input>
              </el-form-item>
              <el-form-item prop="lxr">
                <el-input
                  :placeholder="$t('联系人')"
                  v-model="form.lxr"
                ></el-input>
              </el-form-item>
              <el-form-item prop="sj">
                <el-input
                  :placeholder="$t('电话')"
                  v-model="form.sj"
                ></el-input>
              </el-form-item>
              <el-form-item prop="mail">
                <el-input
                  :placeholder="$t('联系邮箱（必填）')"
                  v-model="form.mail"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item>
              <el-input placeholder="验证码" v-model="form.name">
                <template slot="append">图片验证码</template></el-input
              >
            </el-form-item> -->
              <el-form-item>
                <div class="bth">
                  <el-button
                    style="background: #344f98; color: #fff"
                    v-model="form.name"
                    @click="handleSubmit"
                    >{{ $t('提交') }}</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <BaiduMap
      v-if="$i18n.locale == 'zh'"
      class="map"
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      @ready="handler"
    ></BaiduMap>
  </div>
</template>

<script>
// 写在自己需要用到的组件中
import qs from 'qs'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import { submitInfo } from '@/api/yw/jhsc.js'
export default {
  components: {
    BaiduMap
  },

  data() {
    return {
      rules: {
        mail: [{ required: true, message: '请输入邮箱地址', trigger: 'blur' }],
        ct: [{ required: true, message: '请输入留言内容', trigger: 'blur' }]
      },
      form: {
        mail: '',
        title: '关于OSAI产品的查询',
        ct: '',
        lxr: '',
        sj: ''
      },
      //没有设置 center 和 zoom 属性的地图组件是不进行地图渲染的。
      //当center 属性为合法地名字符串时例外，因为百度地图会根据地名自动调整 zoom 的值。
      center: { lng: 113.605601, lat: 22.368514 },
      zoom: 3,
      map: null,
      BMap: null
    }
  },
  methods: {
    //由于百度地图 JS API 只有 JSONP 一种加载方式，因此 BaiduMap 组件及其所有子组件的渲染只能是异步的。因此，请使用在组件的 ready 事件来执行地图 API 加载完毕后才能执行的代码，不要试图在 vue 自身的生命周期中调用 BMap 类，更不要在这些时机修改 model 层。
    handler({ BMap, map }) {
      this.map = map
      this.BMap = BMap
      // this.center.lng = 113.605601
      // this.center.lat = 22.368514
      this.zoom = 13
      let marker = new BMap.Marker(this.center)
      // 创建自定义覆盖物
      let opt = {
        point: this.center,
        offset: { width: -90, height: -50 }
      }
      let label = new BMap.Label('港湾1号·创意云端A栋', opt)
      marker.setLabel(label)
      label.setStyle({
        width: '200px',
        height: '50px',
        color: '#000',
        border: 0,
        fontSize: '15px',
        textAlign: 'center',
        lineHeight: '50px'
      })
      map.addOverlay(marker)
    },
    //点击提交按钮
    async handleSubmit() {
      await this.$refs.form.validate()
      if (this.$i18n.locale == 'zh') {
        this.form.title = '关于OSAI产品的查询'
      } else {
        this.form.title = 'Inquiry of OSAI products'
      }
      let { msg } = await submitInfo(qs.stringify(this.form))
      this.$message.success(msg)
      await this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  height: 479.25px;
  background: url(../../../assets/关于我们/首页配图.png) no-repeat center;
  background-size: cover;
}
.companyProfile {
  padding-top: 32.75px;
  background: url(../../../assets/关于我们/公司简介底图.png) no-repeat center;
  background-size: cover;
  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: #344f98;
    margin-bottom: 29px;
  }
  .info {
    display: flex;
    justify-content: center;
    height: 336px;
    .zh_item {
      display: flex;
      margin-bottom: 33px;
      .i {
        margin-top: 5px;
        margin-right: 8px;
        border-radius: 50% 50% 50% 50%;
        background: #2a82e4;
        border: 2px solid #ffffff;
        box-shadow: 0px 2px 4px #cbddf4;
        width: 18px;
        height: 18px;
      }
      .text {
        width: 1000px;
        line-height: 28.96px;
        font-size: 20px;
        color: #808080;
      }
    }
    .en_item {
      display: flex;
      // margin-bottom: 33px;
      .i {
        margin-top: 5px;
        margin-right: 8px;
        border-radius: 50% 50% 50% 50%;
        background: #2a82e4;
        border: 2px solid #ffffff;
        box-shadow: 0px 2px 4px #cbddf4;
        width: 18px;
        height: 18px;
      }
      .text {
        width: 1000px;
        line-height: 28.96px;
        font-size: 20px;
        color: #808080;
      }
    }
  }
}
.coreTeamContainer {
  background: linear-gradient(180deg, #ebf1ff 0%, #ffffff 100%);
  .zh_coreTeam {
    height: 690px;
    padding-left: 209px;
    padding-right: 203.54px;
    .title {
      text-align: center;
      padding-top: 38px;
      padding-bottom: 27px;
      font-size: 36px;
      font-weight: 700;
      line-height: 52.13px;
      color: #344f98;
    }
    .img_box {
      display: flex;
      justify-content: space-between;
      .left {
        padding: 0px 21px;
        width: 434px;
        height: 530px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(237, 242, 255, 1) 100%
        );
        box-shadow: 0px 1px 10px rgba(204, 225, 255, 1);
        .photo {
          margin: 19px 18px;
          width: 140px;
          height: 140px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .left_title {
          margin: 18px 7px;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 28.96px;
          color: rgba(56, 56, 56, 1);
        }
        .info {
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(128, 128, 128, 1);
        }
        .name {
          text-align: center;
          font-size: 30px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 43.44px;
          color: #344f98;
        }
      }
      .right {
        padding: 0px 21px;
        width: 434px;
        height: 530px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(237, 242, 255, 1) 100%
        );
        box-shadow: 0px 1px 10px rgba(204, 225, 255, 1);
        .photo {
          margin: 19px 18px;
          width: 140px;
          height: 140px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right_title {
          margin: 18px 7px;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 28.96px;
          color: rgba(56, 56, 56, 1);
        }
        .info {
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(128, 128, 128, 1);
        }
        .name {
          text-align: center;
          font-size: 30px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 43.44px;
          color: #344f98;
        }
      }
    }
  }
  .en_coreTeam {
    height: 950px;
    padding-left: 0px 55px;
    .title {
      text-align: center;
      padding-top: 38px;
      padding-bottom: 27px;
      font-size: 36px;
      font-weight: 700;
      line-height: 52.13px;
      color: #344f98;
    }
    .img_box {
      display: flex;
      justify-content: space-between;
      .left {
        padding: 0px 21px;
        width: 600px;
        height: 800px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(237, 242, 255, 1) 100%
        );
        box-shadow: 0px 1px 10px rgba(204, 225, 255, 1);
        .photo {
          margin: 19px 18px;
          width: 140px;
          height: 140px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .left_title {
          margin: 18px 7px;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 28.96px;
          color: rgba(56, 56, 56, 1);
        }
        .info {
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(128, 128, 128, 1);
          .info_p {
            margin-bottom: 28px;
          }
        }
        .name {
          text-align: center;
          font-size: 30px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 43.44px;
          color: #344f98;
        }
      }
      .right {
        padding: 0px 21px;
        width: 600px;
        height: 800px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(237, 242, 255, 1) 100%
        );
        box-shadow: 0px 1px 10px rgba(204, 225, 255, 1);
        .photo {
          margin: 19px 18px;
          width: 140px;
          height: 140px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right_title {
          margin: 18px 7px;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 28.96px;
          color: rgba(56, 56, 56, 1);
        }
        .info {
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(128, 128, 128, 1);
          .info_p {
            margin-bottom: 28px;
          }
        }
        .name {
          text-align: center;
          font-size: 30px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 43.44px;
          color: #344f98;
        }
      }
    }
  }
}

.companyPatent {
  background: #edf3ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    padding: 31px 0px 40px 0px;
    font-size: 36px;
    font-weight: 700;
    line-height: 52.13px;
    color: #344f98;
  }
  .img {
    width: 1022px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.contactUsContainer {
  background: url(../../../assets/关于我们/蒙版分组.png) no-repeat center;
  background-size: cover;
  .contactUs {
    padding: 34px 90px 72px 143px;
    height: 700px;
    .title {
      color: #fff;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      line-height: 52.13px;
    }
    .centent {
      margin-top: 103px;
      display: flex;
      justify-content: space-between;
      .left {
        .logo {
          width: 101.79px;
          height: 50px;
          margin-bottom: 60px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          color: #808080;
          font-size: 16px;
          font-weight: 400;
          line-height: 23.17px;
          .content_item {
            display: flex;
            margin-bottom: 37.5px;
            .info {
              width: 420px;
            }
          }
        }
        .qrcode_box {
          padding-top: 26.5px;
          color: #808080;
          font-size: 14px;
          font-weight: 500;
          line-height: 20.27px;
          display: flex;

          .item {
            text-align: center;
            width: 93.23px;
            height: 117px;
            margin-right: 8.54px;
            img {
              width: 100%;
            }
            div {
            }
          }
        }
      }
      .right {
        width: 593px;
        height: 100%;
        .bth {
          width: 100%;
          display: flex;
          justify-content: end;
          .el-button {
            height: 40px;
            width: 108px;
          }
        }
        .el-input {
          :deep(.el-input__inner) {
            height: 40px;
          }
          .el-input-group__append {
            padding: 0px;
          }
        }
        .el-form-item {
          margin-bottom: 20px;
        }
        .el-textarea {
          height: 188px;
          :deep(.el-textarea__inner) {
            height: 188px;
          }
        }
      }
    }
  }
}

.map {
  width: 100%;
  height: 440px;
}
</style>
